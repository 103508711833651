import React from 'react';

const SecretNotificationsTest = () => {
  return (
    <div>
      <h2>Secret Notifications Test page</h2>
    </div>
  );
};

export default SecretNotificationsTest;
